import ClearyRichTextField from './clearyRichTextField'
import SimpleClearyRichTextField from './simpleClearyRichTextField'
import TextField from './textField'
import TextareaField from './textareaField'
import FileField from './fileField'
import SelectField from './selectField'
import ArrayField from './arrayField'
import ObjectField from './objectField'
import NumberField from './numberField'

export default function fieldFactory(type) {
  const componentMap = {
    cleary_rich_text: ClearyRichTextField,
    simple_cleary_rich_text: SimpleClearyRichTextField,
    text: TextField,
    textarea: TextareaField,
    file: FileField,
    select: SelectField,
    array: ArrayField,
    object: ObjectField,
    number: NumberField,
  }

  if (!componentMap[type]) {
    throw new Error(`Unknown type: ${type}`)
  }

  return componentMap[type]
}
