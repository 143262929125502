import React from 'react'

const TextField = ({ onChange, value, field }) => (
  <div className='input-group'>
    <input
      type='text'
      className='form-control'
      onChange={e => onChange(e.target.value)}
      value={value}
    />

    {/*
      * TODO: This is used to add the suffix to the generate pdf filename input.
      * This should be refactored to be a more generic solution.
      */}
    {field === 'filename' && (
      <div className='input-group-append'>
        <span className='input-group-text'>
          .pdf
        </span>
      </div>
    )}
  </div>
)

export default TextField
